import SvgIcon from '@mui/material/SvgIcon';

const CloseCircle = () => {
  return (
    <SvgIcon>
      <path
        d="M19.6894 0H4.31058C1.93364 0 0 1.93364 0 4.31058V19.6894C0 22.0664 1.93364 24 4.31058 24H19.6894C22.0664 24 24 22.0664 24 19.6894V4.31058C24 1.93364 22.0664 0 19.6894 0ZM20.2823 18.7294H12.5906C11.9145 20.7305 9.07467 20.7336 8.39766 18.7294H3.71766C2.78381 18.7294 2.78358 17.3176 3.71766 17.3176H8.39766C9.07364 15.3165 11.9136 15.3135 12.5906 17.3176H20.2823C21.2162 17.3176 21.2165 18.7294 20.2823 18.7294ZM20.2823 12.7059H18.6141C17.9381 14.707 15.0982 14.71 14.4211 12.7059H3.71766C2.78381 12.7059 2.78358 11.2941 3.71766 11.2941H14.4212C15.0972 9.29302 17.9371 9.28997 18.6142 11.2941H20.2824C21.2162 11.2941 21.2165 12.7059 20.2823 12.7059ZM20.2823 6.68236H9.57881C8.90278 8.68345 6.06291 8.6865 5.38589 6.68236H3.71766C2.78381 6.68236 2.78358 5.27058 3.71766 5.27058H5.38589C6.06187 3.26948 8.9018 3.26644 9.57881 5.27058H20.2823C21.2162 5.27058 21.2165 6.68236 20.2823 6.68236Z"
        fill="white"
      />
      <path
        d="M10.4941 17.2235C9.43561 17.2235 9.43617 18.8235 10.4941 18.8235C11.5525 18.8235 11.552 17.2235 10.4941 17.2235Z"
        fill="white"
      />
      <path
        d="M7.48237 5.17651C6.42389 5.17651 6.42445 6.7765 7.48237 6.7765C8.54081 6.77654 8.54025 5.17651 7.48237 5.17651Z"
        fill="white"
      />
      <path d="M16.5175 11.2C15.459 11.2 15.4596 12.7999 16.5175 12.7999C17.576 12.8 17.5755 11.2 16.5175 11.2Z" fill="white" />
    </SvgIcon>
  );
};

export default CloseCircle;
