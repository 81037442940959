import SvgIcon from '@mui/material/SvgIcon';

const AlertOutline = () => {
  return (
    <SvgIcon>
      <path fill="currentColor" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
    </SvgIcon>
  );
};

export default AlertOutline;
