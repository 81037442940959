import SvgIcon from '@mui/material/SvgIcon';

const AuditTrail = () => {
  return (
    <SvgIcon>
      <path
        d="M13.1525 6.01261H2.84746C2.09249 6.01332 1.36864 6.31102 0.834794 6.84038C0.300948 7.36974 0.000717996 8.08751 0 8.83613V13.6765C0.000717996 14.4251 0.300948 15.1429 0.834794 15.6722C1.36864 16.2016 2.09249 16.4993 2.84746 16.5H13.1525C13.9075 16.4993 14.6314 16.2016 15.1652 15.6722C15.6991 15.1429 15.9993 14.4251 16 13.6765V8.83613C15.9993 8.08751 15.6991 7.36974 15.1652 6.84038C14.6314 6.31102 13.9075 6.01332 13.1525 6.01261ZM10.6495 10.3313L7.82373 13.1387C7.69618 13.2644 7.52361 13.335 7.34373 13.335C7.16384 13.335 6.99127 13.2644 6.86373 13.1387L5.35051 11.6328C5.28747 11.5703 5.23747 11.4961 5.20336 11.4144C5.16924 11.3327 5.15169 11.2452 5.15169 11.1568C5.15169 11.0684 5.16924 10.9809 5.20336 10.8992C5.23747 10.8175 5.28747 10.7433 5.35051 10.6808C5.41354 10.6183 5.48838 10.5688 5.57073 10.5349C5.65309 10.5011 5.74136 10.4837 5.83051 10.4837C5.91965 10.4837 6.00792 10.5011 6.09028 10.5349C6.17264 10.5688 6.24747 10.6183 6.31051 10.6808L7.34373 11.7081L9.68949 9.37395C9.81718 9.2513 9.98834 9.18329 10.1661 9.18457C10.3439 9.18584 10.514 9.25631 10.6399 9.38078C10.7658 9.50525 10.8373 9.67377 10.8391 9.85004C10.8409 10.0263 10.7728 10.1962 10.6495 10.3232V10.3313Z"
        fill="white"
      />
      <path
        d="M13.4237 4.80252H2.57627C2.3605 4.80252 2.15357 4.71753 2.001 4.56624C1.84843 4.41495 1.76271 4.20975 1.76271 3.9958C1.76271 3.78184 1.84843 3.57665 2.001 3.42536C2.15357 3.27407 2.3605 3.18908 2.57627 3.18908H13.4237C13.6395 3.18908 13.8464 3.27407 13.999 3.42536C14.1516 3.57665 14.2373 3.78184 14.2373 3.9958C14.2373 4.20975 14.1516 4.41495 13.999 4.56624C13.8464 4.71753 13.6395 4.80252 13.4237 4.80252Z"
        fill="white"
      />
      <path
        d="M12.339 2.11345H3.66102C3.44525 2.11345 3.23832 2.02845 3.08574 1.87716C2.93317 1.72587 2.84746 1.52068 2.84746 1.30672C2.84746 1.09277 2.93317 0.887574 3.08574 0.736284C3.23832 0.584994 3.44525 0.5 3.66102 0.5H12.339C12.5548 0.5 12.7617 0.584994 12.9143 0.736284C13.0668 0.887574 13.1525 1.09277 13.1525 1.30672C13.1525 1.52068 13.0668 1.72587 12.9143 1.87716C12.7617 2.02845 12.5548 2.11345 12.339 2.11345Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default AuditTrail;
