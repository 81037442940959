import SvgIcon from '@mui/material/SvgIcon';

const VerifyUser = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path
        d="M7.62582 8.18376C9.8857 8.18376 11.7177 6.35177 11.7177 4.09188C11.7177 1.832 9.8857 0 7.62582 0C5.36593 0 3.53394 1.832 3.53394 4.09188C3.53394 6.35177 5.36593 8.18376 7.62582 8.18376Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68595 15.2605C7.91296 14.4302 7.43979 13.3172 7.43979 12.0941C7.43979 10.842 7.93602 9.70446 8.74249 8.86822C8.37794 8.83995 8.00521 8.82507 7.62578 8.82507C5.15428 8.82507 2.96624 9.44332 1.60774 10.3696C0.571375 11.0764 0 11.9743 0 12.917V13.9957C0 14.3313 0.133172 14.6534 0.370501 14.89C0.60783 15.1273 0.929229 15.2605 1.26476 15.2605H8.68595Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0897 8.18823C9.93368 8.18823 8.18384 9.93807 8.18384 12.0941C8.18384 14.2502 9.93368 16 12.0897 16C14.2458 16 15.9956 14.2502 15.9956 12.0941C15.9956 9.93807 14.2458 8.18823 12.0897 8.18823ZM10.2923 12.7444L11.4082 13.4883C11.6292 13.6356 11.9246 13.6066 12.112 13.4184L13.972 11.5585C14.19 11.3412 14.19 10.9871 13.972 10.7698C13.7547 10.5519 13.4006 10.5519 13.1834 10.7698L11.6463 12.3062L10.9113 11.8159C10.6553 11.6448 10.3086 11.7147 10.1375 11.9706C9.96641 12.2265 10.0363 12.5732 10.2923 12.7444Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default VerifyUser;
